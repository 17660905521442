import { readState } from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import { mergeArrayObjectsById, mergeLocalWithApi } from "@/app/util.mjs";
import { ApexMatchModel } from "@/data-models/apex-match.mjs";
import { ApexMatchesModel } from "@/data-models/apex-matches.mjs";
import { ApexPlayerModel } from "@/data-models/apex-player.mjs";
import { ApexPlayerStatsModel } from "@/data-models/apex-player-stats.mjs";
import { ApexRankedStatsAggModel } from "@/data-models/apex-ranked-stats-agg.mjs";
import * as API from "@/game-apex/api.mjs";
import GameMode from "@/game-apex/constants/game-modes.mjs";
import {
  getCurrentSeason,
  getRankedModeByGameMode,
} from "@/game-apex/utils.mjs";
import { devError } from "@/util/dev.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

function fetchData(
  [profileIdRouteParam, seasonRouteParam, matchIdRouteParam]: FixedLengthArray<
    string,
    3
  >,
  searchParam: URLSearchParams,
) {
  const profileId = profileIdRouteParam || searchParam.get("profileId");
  const season = seasonRouteParam || searchParam.get("season");
  const matchId = matchIdRouteParam || searchParam.get("matchId");

  const isOwnAccount = Boolean(
    readState.settings.loggedInAccounts.apex[profileId],
  );

  const expiryTime = !isOwnAccount ? null : MAX_TIME;

  const seasonParam = season || "ALL";
  const seasonId = readState.apex.meta.seasons?.[seasonParam]?.id;

  const promises = [
    getData(
      API.getPlayer({ platformId: profileId }),
      ApexPlayerModel,
      ["apex", "profiles", profileId],
      {
        expiryTime,
        mergeFn: mergeLocalWithApi,
      },
    ),
    ...(matchId
      ? [
          getData(
            API.getMatch({
              seasonId,
              apexId: matchId,
            }),
            ApexMatchModel,
            ["apex", "matches", matchId],
            {
              shouldFetchIfPathExists: false,
              expiryTime,
              mergeFn: mergeLocalWithApi,
            },
          ),
        ]
      : []),
  ] as const;

  return Promise.all(promises).then(([profile, match]) => {
    const mode = match?.["gameMode"] || (isRouteOverlay() ? "ALL" : null);

    if (mode) {
      getData(
        API.getMatchlist({
          ...(seasonParam === "ALL" ? {} : { seasonId }),
          ...(mode === "ALL" ? {} : { gameMode: mode }),
          platformProfileId: profile.id,
        }),
        ApexMatchesModel,
        ["apex", "matchlists", profileId, seasonParam, mode],
        {
          expiryTime,
          mergeFn: (curValue, newValue) =>
            mergeArrayObjectsById(
              curValue,
              newValue,
              "apexId",
              "gameStartedAt",
            ),
        },
      ).then((matches) => {
        if (Array.isArray(matches))
          for (const match of matches) {
            getData(
              API.getMatch({
                seasonId: match.season.id,
                apexId: match.apexId,
              }),
              ApexMatchModel,
              ["apex", "matches", match.apexId],
              {
                skipSafetyCheck: true,
                shouldFetchIfPathExists: false,
                expiryTime,
                mergeFn: mergeLocalWithApi,
              },
            );
          }
      });
    }

    if (isRouteOverlay()) {
      const currentSeason = getCurrentSeason(readState.apex.meta.seasons);
      const rankedMode = getRankedModeByGameMode(mode);
      if (rankedMode && currentSeason)
        getData(
          API.getRankedStatsAgg({
            seasonId: currentSeason?.id,
            gameMode: rankedMode,
          }),
          ApexRankedStatsAggModel,
          ["apex", "rankedStatsAgg", currentSeason.apexId, rankedMode],
          {
            expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        );

      if (profile?.id) {
        getData(
          API.getPlayerSeasonStats({
            platformProfileId: profile.id,
            gameMode: rankedMode,
            seasonId: match ? match?.season?.id || currentSeason?.id : "1",
          }),
          ApexPlayerStatsModel,
          [
            "apex",
            "playerStats",
            profileId,
            match ? match?.season?.apexId || currentSeason?.apexId : "ALL",
            rankedMode,
          ],
          {
            shouldFetchIfPathExists: false,
            expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        ).catch((error) => {
          if (error?.errorCode === "not_found") return;
          devError(`Error fetching apex getPlayerSeasonStats`, error);
        });
      }
    }

    if (match && "playerMatchStats" in match && matchId) {
      for (const player of match.playerMatchStats) {
        getData(
          API.getPlayer({ platformId: player.platformId }),
          ApexPlayerModel,
          ["apex", "profiles", player.platformId],
          {
            skipSafetyCheck: true,
            expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        ).then((profile) => {
          if (profile?.id && profile?.hoveredChampionApexId)
            getData(
              API.getPlayerSeasonStats({
                gameMode: GameMode.RANKED,
                platformProfileId: profile.id,
                seasonId: 1,
              }),
              ApexPlayerStatsModel,
              [
                "apex",
                "playerStats",
                player.platformId,
                "ALL",
                GameMode.RANKED,
              ],
              {
                skipSafetyCheck: true,
                shouldFetchIfPathExists: false,
                expiryTime,
                mergeFn: mergeLocalWithApi,
              },
            );
        });
      }
    }
  });
}

export default fetchData;
